// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-analytics-js": () => import("./../../../src/pages/analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-hold-js": () => import("./../../../src/pages/hold.js" /* webpackChunkName: "component---src-pages-hold-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mine-js": () => import("./../../../src/pages/mine.js" /* webpackChunkName: "component---src-pages-mine-js" */)
}

